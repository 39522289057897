<template>
  <div class="visual-holder w-100 position-relative border-bottom">
    <div class="visual-image">
      <img class="eucalyptus" src="../../assets/SeededEucalyptus_08.png">
      <img class="flower" src="../../assets/flower.png">
      <div class="portrait">
        <img class="img-fluid" src="../../assets/portrait.jpg">
        <div class="text-center">
          <div class="text-secondary"><strong>ANGELIKA THIESSEN</strong></div>
          <div class="text-secondary"><strong>(GEB. RÖMMICH)</strong></div>
          <div class="text-primary">* 18.10.1983 - † 20.11.2019</div>
        </div>
      </div>
    </div>
    <div class="container position-relative">
      <div class="visual-text-wrap position-absolute">
        <div class="visual-text">
          <h1 class="text-primary text-1">Das Beste</h1>
          <h1 class="text-primary text-2">kommt</h1>
          <h1 class="text-primary text-3">noch...</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
