<template>
    <navbar></navbar>
</template>

<script>
import Navbar from '../navbar'
export default {
  components: { Navbar },
}
</script>
