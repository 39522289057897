<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <navbar-brand></navbar-brand>
  </nav>
</template>

<script>
import NavbarBrand from './navbar/navbar-brand'

export default {
  components: { NavbarBrand },
  name: 'navbar',
}
</script>

<style scoped>

</style>
