<template>
  <div class="row justify-content-between py-3">
    <div class="col col-md-3">
      <small class="text-white">© 2020 by Peter Thiessen</small>
    </div>
    <div class="col col-md-4">
      <ul class="list-unstyled nav text-right">
        <li class="nav-item col">
          <router-link to="/datenschutzerklaerung"><small class="text-white">Datenschutzerklärung</small></router-link>
        </li>
        <li class="nav-item col">
          <a href="#">
          <router-link to="/impressum"><small class="text-white">Impressum</small></router-link>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
