<template>
  <div class="container">
    <h3>DAS BESTE KOMMT NOCH <br/> DIE KUCHENGABEL</h3>
    <div class="row row-cols-1 mb-6 mb-lg-7">
      <div class="col d-md-none">
        <img src="../../assets/MG_5067-1000x720.jpg" alt="fork" class="img-fluid mb-2">
      </div>

      <div class="col ">
        <img src="../../assets/MG_5067-1000x720.jpg" alt="fork"
             class="d-none d-md-block img-fluid mb-2 mb-md-0 mr-2 float-left w-50">
        <p>Einer Frau wurde eine unheilbare Krankheit diagnostiziert. Der Arzt sagte, sie hätte nur
          noch drei Monate zu leben. Sie fing also an, alles in Ordnung zu bringen und alles zu organisieren. Sie rief
          auch den Pastor an, und bat ihn zu ihr zu kommen, um ihre Wünsche für die Beerdigung abzusprechen. Sie sagte
          ihm, welche Lieder gesungen werden sollten, welche Bibeltexte gelesen werden sollten und in welchem Kleid sie
          beerdigt werden wollte. Sie sagte auch, dass sie ihre Bibel in der linken Hand halten wolle.</p>

        <div class="text-center" v-if="collapsed" v-on:click.prevent="toggle">
          <a href="#">mehr</a>
        </div>
        <div class="collapse" :class="{'show': !collapsed}">
          <p>Als der Pastor gehen wollte, erinnerte sich die Frau an ein wichtiges Detail, das sie vergessen hatte. <em>„Herr
            Pastor, eins noch…“</em>, sagte sie aufgeregt. <em>„Das ist auch noch sehr wichtig für mich! Ich möchte,
            dass ich eine Gabel in meiner rechten Hand halte, wenn ich im Sarg aufgebahrt werde.“</em> Dem Pastor
            fehlten buchstäblich die Worte. <em>„Das überrascht Sie sicher.“</em> fuhr die Frau ruhig fort. <em>„Oh
              ja!“</em>, stammelte die Geistliche.</p>
          <p>Die Frau fing an zu erklären: <em>„In all den Jahren, in denen ich an den vielen Empfängen teilnahm, wurde
            ich immer mal wieder daran erinnert, meine Gabel zu behalten, wenn das Geschirr abgeräumt wurde. ‚Behalten
            Sie ihre Gabel‘ – irgendeiner sagte es bestimmt. Und ich freute mich dann immer, denn ich wusste, dass noch
            etwas Besseres kommen würde! Leckere Schokoladendesserts, Kuchen, Apfelstrudel oder Eis. Irgend etwas
            wunderbares, was das große Mahl perfekt abrundete.“&nbsp;</em>Der Pastor lächelte. <em>„Ich möchte“</em>,
            fuhr die Frau fort, <em>„dass die Leute, die mich im Sarg sehen, sich wundern, warum ich die Gabel in der
              Hand halte. Und ich will, dass Sie ihnen sagen: ‚Behalten Sie ihre Gabel – das Beste kommt erst
              noch!'“</em></p>
          <p>Der Pastor fing beinahe an zu weinen, als er die Frau beim Abschied umarmte. Er wusste, dass es eines der
            letzten Male war, bevor sie sterben würde. Aber er wusste auch, dass die Frau eine bessere Vorstellung vom
            Himmel hatte, als er selbst. Sie WUSSTE und vertraute, dass noch etwas Besseres auf sie wartete.</p>
          <p>An der Beerdigung gingen die Menschen an ihrem Sarg vorbei und sahen ihr schönes Kleid, ihre Bibel in der
            linken Hand und … die Gabel in der Rechten! Und immer wieder hörte der Pastor die Frage: <em>„Warum hat sie
              eine Gabeln in der Hand?“</em> und sein Lachen wurde größer und freudiger bei jedem Mal!<br>Während der
            Predigt berichtete der Pastor von der Unterhaltung, die er vor kurzem noch mit der Frau hatte. Er erklärte,
            was die Gabel in der Hand sollte und was sie der Frau bedeutete, dass er nicht mehr aufhören konnte, an die
            Gabel zu denken un dass er hoffe, dass auch die Anwesenden die Gabeln nie mehr vergessen würden.</p>
          <p>Die Bedeutung, die die Gabel für die Frau hatte, schlug bei den Leuten ein! Sie erzählen sie sich noch
            heute.</p>
          <p>Das Beste kommt noch!</p>
        </div>
        <div class="text-center" v-if="!collapsed" v-on:click.prevent="toggle">
          <a href="#" class="text-decoration-none">weniger</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'story',
  methods: {
    toggle: function () {
      this.collapsed = !this.collapsed
    },
  },
  data: function () {
    return {
      collapsed: true,
    }
  },
}
</script>
