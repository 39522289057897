<template>
  <div class="container">
    <div class="row">
      <div class="col col-md-8">
        <h2>Offenbarung 21 </h2>
        <p>Dann sah ich einen neuen Himmel und eine neue Erde, denn der alte Himmel und die alte Erde waren
          verschwunden.
          Und auch das Meer war nicht mehr da. Und ich sah die heilige Stadt, das neue Jerusalem, von Gott aus dem
          Himmel
          herabkommen wie eine schöne Braut, die sich für ihren Bräutigam geschmückt hat. Ich hörte eine laute Stimme
          vom
          Thron her rufen: »Siehe, die Wohnung Gottes ist nun bei den Menschen! Er wird bei ihnen wohnen und sie werden
          sein
          Volk sein und Gott selbst wird bei ihnen sein. Er wird alle ihre Tränen abwischen, und es wird keinen Tod und
          keine Trauer und kein Weinen und keinen Schmerz mehr geben. Denn die erste Welt mit ihrem ganzen Unheil ist
          für
          immer vergangen.« Und der, der auf dem Thron saß, sagte: »Ja, ich mache alles neu!« Und dann sagte er zu mir:
          »Schreib es auf, denn was ich dir sage, ist zuverlässig und wahr!« Und er sagte auch: »Es ist vollendet! Ich
          bin
          das Alpha und das Omega – der Anfang und das Ende. Jedem, der durstig ist, werde ich aus der Quelle, die das
          Wasser des Lebens enthält, umsonst zu trinken geben! Wer siegreich ist, wird dies alles empfangen; ich werde
          sein
          Gott sein, und er wird mein Sohn sein. Doch die Feigen und Treulosen und diejenigen, die abscheuliche Taten
          tun
          und die Mörder und Unzüchtigen und die, die Zauberei treiben, die Götzendiener und alle Lügner – sie erwartet
          der
          See, der mit Feuer und Schwefel brennt. Das ist der zweite Tod.«</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'offenbarung',
}
</script>
