<template>
  <div class="bg-light position-relative">
    <div class="container-fluid container-xl">
      <div class="row align-items-center row-cols-1 row-cols-md-2 gy-0" :class="{'flex-row-reverse': reverted}">
        <div class="col" :class="{'pr-lg-7 pl-3 pl-lg-7': !reverted, 'pl-md-7': reverted}">
          <h3 class="mt-6 mt-md-0">{{ title }} </h3>
          <div class="mt-3 mb-2">
            <img src="../../assets/promize-icone.png" alt="">
          </div>
          <p>
            <slot></slot>
          </p>
        </div>
        <div class="col"><img class="vw" :src="image" alt="" :height="mediaHeight + 'px'"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'media',
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    reverted: {
      type: Boolean,
      required: false,
      default: false,
    },
    mediaHeight: {
      type: Number,
      required: false,
      default: 400,
    },
  },
}
</script>

<style scoped lang="scss">
@import "../../sass/variables";
p {
  color: $primary;
}
</style>
