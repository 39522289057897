import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import header from '@/components/layout/header'
import footer from './components/layout/footer'
import visual from './components/layout/visual'
import main from './components/layout/main'

Vue.component('template-header', header)
Vue.component('template-footer', footer)
Vue.component('visual', visual)
Vue.component('main-part', main)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
