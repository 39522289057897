<template>
  <main id="main">
    <offenbarung></offenbarung>
    <story></story>

    <media title="24. Mai 2008" image="/images/IMG_4998-scaled.jpg">
      Unsere Hochzeit. Ein Traumtag. Wir hatten uns all
      die Jahre, bis zum Schluss, gerne daran zurückerinnert.
    </media>

    <media title="Oktober 2010" :reverted="true" image="/images/Angi-11.2010.jpg">
      Angelika arbeitete im Jugendwerk Süddeutscher Mennoniten in Karlsruhe. Unserer erster gemeinsamer Wohnort.
    </media>

    <media title="24. Dezember 2011" image="/images/Angi-und-Noah12.2011-scaled.jpg">
      Unser erstes Wunschkind Noah! 3 Monate alt.
    </media>

    <media title="23. Juni 2015" :reverted="true" image="/images/Angi-und-Sophia06.2015-scaled.jpg">
      Unsere Tochter Sophia wird auf der Frühchenstation von Angelika gefüttert. Am nächsten Tag war Angelika nicht mehr
      dazu in der Lage.
    </media>

    <media title="17. Juli 2015" image="/images/Sophias-Geburt-scaled.jpg" >
      Unser zweites Wunschkind Sophia wurde am 19.06.2015 geboren. Das Foto konnte erst 1 Monat später gemacht werden,
      da Mutter und Kind vorher nicht in der Verfassung waren.
    </media>

    <media title="Oktober 2015" :reverted="true" image="/images/Aus-dem-Koma-erwacht-10.2015-scaled.jpg">
      Vor kurzem aus dem mehrwöchigem „Koma“ erwacht. Sie kann sich zum erstenmal wieder an die Kinder erinnern wenn
      auch nur wage. Das Foto entstand um es bei ihr zur Festigung der Erinnerung aufzuhängen.
    </media>

    <media title="30. April 2016" image="/images/Endlich-daheim-30.04.16-scaled.jpg">
      Endlich daheim! Nach über 10 Monate Krankenhausaufenthalt ist die Mama wieder da…
    </media>

    <media title="18. September 2016" :reverted="true" image="/images/085b-scaled.jpg">
      Wir feiern Noahs 5.Geburtstag. Angelika ist im Familienleben angekommen.
    </media>

    <media title="24. Dezember 2017" image="/images/Weihnachten2017-scaled.jpg">
      Weihnachten.
    </media>

    <gallery></gallery>
  </main>
</template>

<script>
import offenbarung from '../content/offenbarung'
import story from '../content/story'
import media from '../content/media'
import gallery from '../content/gallery'

export default {
  components: { offenbarung, story, media, gallery },
}
</script>
