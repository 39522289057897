<template>
  <div class="navbar-brand py-3">
    <router-link to="/" class="navbar-item text-decoration-none">
      <h3 class="text-dark m-0">
        Gedenkseite von Angelika Thiessen
      </h3>
      <span>* 18.10.1983 - † 20.11.2019</span>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'navbar-brand',
}
</script>

<style scoped lang="scss">
  h3 {
    font-size: 1.25rem;
  }
  span {
    font-size: 1rem;
  }
</style>
